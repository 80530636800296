<div [formGroup]="form">
  <label [attr.for]="name" class="form-label" [class.hidden]="!label">{{ label }}:</label>
  <input type="text" class="form-control" [id]="name" [formControlName]="name" autocomplete="off"
    [ngClass]="{ 'is-invalid': submitted && form.controls[name].invalid }" [required]="required"
    [attr.disabled]="disabled ? '' : null" bsDatepicker [minDate]="min" [maxDate]="max"
    [bsConfig]="{ dateInputFormat: 'D. M. YYYY', isAnimated: true, adaptivePosition: true, customTodayClass: 'today' }"
    placeholder="--. --. ----">
  <div class="invalid-feedback" *ngIf="submitted && form.controls[name].invalid">
    <ng-container *ngIf="form.controls[name].errors.required; else second">
      This field is required.
    </ng-container>
    <ng-template #second>
      <ng-container *ngIf="form.controls[name].errors.min; else third">
        Minimum is {{ form.controls[name].errors.min.min | date }}.
      </ng-container>
    </ng-template>
    <ng-template #third>
      <ng-container *ngIf="form.controls[name].errors.max; else fourth">
        Maximum is {{ form.controls[name].errors.max.max | date }}.
      </ng-container>
    </ng-template>
    <ng-template #fourth>
      <ng-container *ngIf="form.controls[name].errors.bsDate">
        <div *ngIf="form.controls[name].errors.bsDate.minDate">
          Minimum is {{ form.controls[name].errors.bsDate.minDate | date }}.
        </div>
        <div *ngIf="form.controls[name].errors.bsDate.maxDate">
          Maximum is {{ form.controls[name].errors.bsDate.maxDate | date }}.
        </div>
      </ng-container>
    </ng-template>
  </div>
</div>

<div [formGroup]="form">
  <div class="typeahead">
    <label [attr.for]="name" class="form-label"
      [class.hidden-sm]="labelHidden">{{ labelHidden ? '' : label + ':' }}</label>
    <input type="time" class="form-control" [id]="name" [formControlName]="name" [required]="required"
      [ngClass]="{ 'is-invalid': this.submitted && this.form.controls[this.name].invalid }" autocomplete="off"
      placeholder="--:--" [attr.disabled]="disabled ? '' : null">
  </div>
  <div class="invalid-feedback" *ngIf="this.submitted && this.form.controls[this.name].invalid">
    <div *ngIf="form.controls[name].errors.required">
      This field is required.
    </div>
    <div *ngIf="form.controls[name].errors.min">
      Minimum is {{ form.controls[name].errors.min.min }}.
    </div>
    <div *ngIf="form.controls[name].errors.max">
      Maximum is {{ form.controls[name].errors.max.max }}.
    </div>
    <div *ngIf="form.controls[name].errors.pattern">
      This field doesn't match the required pattern.
    </div>
  </div>
</div>

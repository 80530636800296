<header>
  <nav class="container">
    <button *ngIf="(auth.user$ | async)?.id" (click)="collapsed = !collapsed" [class.is-active]="!collapsed"
      class="hamburger hamburger--slider" aria-label="Menu" aria-controls="navigation">
      <span class="hamburger-box">
        <span class="hamburger-inner"></span>
      </span>
    </button>
    <a [routerLink]="[(auth.user$ | async)?.id ? '/reservations' : '/']" id="headline" (click)="collapsed = true">
      <fa-icon [icon]="logo"></fa-icon>Olymp <span>Reservations</span>
    </a>
    <ul [@SlideInOutAnimation]="collapsed ? true : false" (click)="collapsed = true">
      <ng-container *ngIf="(auth.user$ | async)?.id">
        <li routerLinkActive="active"><a [routerLink]="['/reservations']">Reservations</a></li>
        <li routerLinkActive="active"><a [routerLink]="['/rooms']">Rooms</a></li>
        <li routerLinkActive="active"><a [routerLink]="['/logout']">Log out</a></li>
      </ng-container>
    </ul>
  </nav>
</header>

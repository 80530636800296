import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { QuicklinkStrategy } from 'ngx-quicklink';
import { AuthGuard } from '@guards/auth.guard';

const routes: Routes = [
  {
    path: 'rooms',
    loadChildren: () =>
      import('./modules/rooms/rooms.module').then(m => m.RoomsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'reservations',
    loadChildren: () =>
      import('./modules/reservations/reservations.module').then(
        m => m.ReservationsModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: 'users',
    loadChildren: () =>
      import('./modules/users/users.module').then(m => m.UsersModule),
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: QuicklinkStrategy
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}

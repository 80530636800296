import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { dateMinValidator } from 'src/app/core/utils/validators';

@Component({
  selector: 'app-form-date',
  templateUrl: './form-date.component.html',
  styleUrls: ['./form-date.component.scss']
})
export class FormDateComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() submitted: boolean;
  @Input() name: string;
  @Input() label: string;
  @Input() min: Date;
  @Input() max: Date;
  @Input() required: boolean;
  @Input() disabled: boolean;
  lastMidnight = new Date(new Date().setHours(0, 0, 0, 0));

  constructor() {}

  ngOnInit() {
    const control = this.form && this.form.get(this.name);
    if (control) {
      if (this.min !== null) {
        const lower =
          this.min && this.min > this.lastMidnight
            ? this.min
            : this.lastMidnight;
        this.min = lower;
        control.setValidators(
          control.validator
            ? [control.validator, dateMinValidator(lower)]
            : dateMinValidator(lower)
        );
      }
    }
  }
}

<div [formGroup]="form">
  <label *ngIf="label" [attr.for]="name" class="form-label">{{ label }}:</label>
  <select class="form-control" [id]="name" [formControlName]="name"
    [ngClass]="{ 'is-invalid': submitted && form.controls[name].invalid }" [required]="required">
    <option value="" selected disabled hidden>Choose...</option>
    <option *ngFor="let item of data" [value]="item.id">{{ item.name }}</option>
  </select>
  <div class="invalid-feedback" *ngIf="submitted && form.controls[name].invalid">
    <div *ngIf="form.controls[name].errors.required">
      This field is required.
    </div>
  </div>
</div>

<div [formGroup]="form">
  <label *ngIf="label" [attr.for]="name" class="form-label">{{ label }}:</label>
  <div class="display-flex">
    <div class="dropdown-wrapper">
      <input type="text" autocomplete="off" class="form-control" [id]="name" [formControlName]="'add_'+name"
        [attr.placeholder]="placeholder" [typeahead]="data" typeaheadOptionsLimit="20"
        [typeaheadOptionField]="dataField" [adaptivePosition]="false" (typeaheadOnSelect)="onAdd($event)"
        [typeaheadMinLength]="start" [class.is-invalid]="submitted && form.controls[name].invalid" [required]="required"
        [attr.disabled]="form?.value[name] ? true : null">
      <div class="invalid-feedback" *ngIf="submitted && form.controls[name].invalid">
        <div *ngIf="form.controls[name].errors.required">
          This field is required.
        </div>
      </div>
    </div>
    <button *ngIf="single && form?.value[name]" type="button" class="btn btn-secondary m-0 ml-05"
      (click)="onRemove()">Clear</button>
  </div>
</div>

import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-form-autofill',
  templateUrl: './form-autofill.component.html',
  styleUrls: ['./form-autofill.component.scss']
})
export class FormAutofillComponent {
  @Input() form: FormGroup;
  @Input() name: string;
  @Input() data: any[];
  @Input() dataField: string;
  @Input() origin: string;
  @Input() start: number;
  @Input() label: string;
  @Input() required: boolean;
  @Input() submitted: boolean;
  @Input() placeholder: string;
  @Input() single: boolean;

  constructor() { }

  onAdd(value) {
    if (this.single) {
      this.form.patchValue({ [this.name]: value.item });
    } else {
      const id = value.item.id;
      const selected = this.data.filter(x => x.id === id)[0];
      this.data.splice(this.data.indexOf(this.data.find(x => x.id === id)), 1);
      this.form?.value[this.origin].push(selected);
      this.form.patchValue({ ['add_' + this.name]: '' });
    }
  }

  onRemove() {
    this.form.patchValue({ ['add_' + this.name]: '' });
    this.form.patchValue({ [this.name]: null });
  }
}

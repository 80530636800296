import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-form-time',
  templateUrl: './form-time.component.html',
  styleUrls: ['./form-time.component.scss']
})
export class FormTimeComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() submitted: boolean;
  @Input() name: string;
  @Input() label: string;
  @Input() labelHidden: boolean;
  @Input() min: string;
  @Input() max: string;
  @Input() required: boolean;
  @Input() disabled: boolean;
  regexTime = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;
  times = [];

  constructor() {}

  ngOnInit() {
    for (let i = 0; i < 24; i++) {
      this.times.push(i > 9 ? `${i}:00` : `0${i}:00`);
      this.times.push(i > 9 ? `${i}:30` : `0${i}:30`);
    }
    const control = this.form && this.form.get(this.name);
    if (control) {
      control.setValidators(
        control.validator
          ? [control.validator, Validators.pattern(this.regexTime)]
          : Validators.pattern(this.regexTime)
      );
    }
  }

  onSelect(time: string) {
    this.form.patchValue({ [this.name]: time });
  }
}

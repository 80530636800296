<div [formGroup]="form">
  <label [attr.for]="name" class="form-label" [class.hidden]="!label">{{ label || placeholder }}:</label>
  <input [type]="type" class="form-control" [id]="name" [formControlName]="name" [attr.min]="min" [attr.max]="max"
    [ngClass]="{ 'is-invalid': this.submitted && this.form.controls[this.name].invalid }"
    [attr.placeholder]="placeholder" [attr.required]="required ? '' : null" [attr.disabled]="disabled ? '' : null"
    [attr.autocomplete]="name !== 'email' && name !== 'password' ? 'off' : null">
  <div class="invalid-feedback" *ngIf="this.submitted && this.form.controls[this.name].invalid">
    <div *ngIf="form.controls[name].errors.required">
      This field is required.
    </div>
    <div *ngIf="form.controls[name].errors.min">
      Minimum is {{ form.controls[name].errors.min.min }}.
    </div>
    <div *ngIf="form.controls[name].errors.max">
      Maximum is {{ form.controls[name].errors.max.max }}.
    </div>
    <div *ngIf="form.controls[name].errors.email">
      Not a valid email address.
    </div>
    <div *ngIf="form.controls[name].errors.minlength">
      Minimum length is {{ form.controls[name].errors.minlength.requiredLength }} characters.
    </div>
    <div *ngIf="form.controls[name].errors.maxlength">
      Maximum length is {{ form.controls[name].errors.maxlength.requiredLength }} characters.
    </div>
    <div *ngIf="form.controls[name].errors.passwordCheck">
      Passwords do not match.
    </div>
    <div *ngIf="form.controls[name].errors.pattern">
      This field doesn't match the required pattern.
    </div>
  </div>
</div>

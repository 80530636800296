import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';

export function PasswordCheckValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const parent = control.parent;
    if (parent) {
      return parent.get('password').value !== control.value
        ? { passwordCheck: true }
        : null;
    }
    return null;
  };
}

export function dateMinValidator(date: Date): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value) {
      return control.value < date ? { min: { min: date } } : null;
    }
  };
}

<div class="alert alert-danger" *ngIf="error">{{ error }}</div>
<h1>Welcome</h1>
<ng-container *ngIf="!isLoading; else loading">
  <p>Use your Hydra credentials to login.</p>
  <form class="form-grid" [formGroup]="form" (ngSubmit)="onSubmit()">
    <fieldset>
      <app-form-input type="text" name="username" [label]="false" placeholder="Username" [submitted]="submitted"
        [required]="true" [form]="form"></app-form-input>
    </fieldset>
    <fieldset>
      <app-form-input type="password" name="password" [label]="false" placeholder="Password" [submitted]="submitted"
        [required]="true" [form]="form"></app-form-input>
    </fieldset>
    <button [disabled]="isLoading" class="btn btn-primary">Log in</button>
  </form>
</ng-container>
<ng-template #loading>
  <app-loading-spinner></app-loading-spinner>
</ng-template>

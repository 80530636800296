import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
  name: 'arrayFilter'
})
export class ArrayFilterPipe implements PipeTransform {
  transform(value: any[], arg: any): any {
    return value?.length && arg && value.filter(e => e.id === arg.id).length;
  }
}

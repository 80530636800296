import {
  trigger,
  state,
  style,
  transition,
  animate
} from '@angular/animations';

export const SlideInOutAnimation = [
  trigger('SlideInOutAnimation', [
    state('true', style({ height: '0' })),
    state('false', style({ height: '*' })),
    transition('false <=> true', animate('200ms ease-in-out'))
  ])
];

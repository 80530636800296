import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss']
})
export class FormInputComponent {
  @Input() form: FormGroup;
  @Input() submitted: boolean;
  @Input() name: string;
  @Input() type: string;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() min: string;
  @Input() max: string;
  @Input() required: boolean;
  @Input() disabled: boolean;
}

import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-form-select',
  templateUrl: './form-select.component.html',
  styleUrls: ['./form-select.component.scss']
})
export class FormSelectComponent {
  @Input() form: FormGroup;
  @Input() name: string;
  @Input() label: string;
  @Input() data: { id: any; name: any }[];
  @Input() submitted: boolean;
  @Input() required: boolean;
}

import { Component, Input } from '@angular/core';
import { FormGroup, FormArray, FormControl } from '@angular/forms';

@Component({
  selector: 'app-form-checkbox',
  templateUrl: './form-checkbox.component.html',
  styleUrls: ['./form-checkbox.component.scss']
})
export class FormCheckboxComponent {
  @Input() form: FormGroup;
  @Input() name: string;
  @Input() label: string;
  @Input() text: string;
  @Input() value: number;
  @Input() required: boolean;
  @Input() submitted: boolean;
  @Input() array: FormArray;

  isChecked() {
    if (this.array) {
      return this.array.value.includes(this.value);
    } else {
      return this.form?.controls[this.name].value;
    }
  }

  onCheckChange(event) {
    if (this.array) {
      if (event.target.checked) {
        this.array.push(new FormControl(+event.target.value));
      } else {
        let i = 0;
        this.array.controls.forEach((ctrl: FormControl) => {
          if (ctrl.value === +event.target.value) {
            this.array.removeAt(i);
            return;
          }
          i++;
        });
      }
    } else {
      this.form.controls[this.name].setValue(
        !this.form.controls[this.name].value
      );
    }
  }
}

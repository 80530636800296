import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { AuthService } from '@services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  form: FormGroup;
  error: string;
  isLoading: boolean;
  submitted: boolean;

  constructor(
    private auth: AuthService,
    private fb: FormBuilder,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  onSubmit() {
    this.submitted = true;
    this.error = null;
    if (this.form.invalid) {
      return;
    }
    this.isLoading = true;
    this.auth.signIn(this.form.value).subscribe(
      () => this.router.navigate(['/reservations']),
      error => {
        this.error = error.error.error.message;
        this.isLoading = false;
      }
    );
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { QuicklinkModule } from 'ngx-quicklink';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';

import { HeaderComponent } from './components/layout/header/header.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { MainComponent } from './components/layout/main/main.component';
import { FormInputComponent } from './components/form/form-input/form-input.component';
import { FormDateComponent } from './components/form/form-date/form-date.component';
import { FormTimeComponent } from './components/form/form-time/form-time.component';
import { FormCheckboxComponent } from './components/form/form-checkbox/form-checkbox.component';
import { FormSelectComponent } from './components/form/form-select/form-select.component';
import { FormAutofillComponent } from './components/form/form-autofill/form-autofill.component';
import { LoadingSpinnerComponent } from './components/atoms/loading-spinner/loading-spinner.component';
import { NotFoundComponent } from './components/atoms/not-found/not-found.component';

import { ArrayFilterPipe } from './pipes/array-filter.pipe';

const components = [
  HeaderComponent,
  MainComponent,
  FooterComponent,
  FormInputComponent,
  FormDateComponent,
  FormTimeComponent,
  FormCheckboxComponent,
  FormSelectComponent,
  FormAutofillComponent,
  LoadingSpinnerComponent,
  NotFoundComponent
];

const pipes = [ArrayFilterPipe];

FullCalendarModule.registerPlugins([
  dayGridPlugin,
]);

@NgModule({
  declarations: [...components, ...pipes],
  imports: [
    RouterModule,
    CommonModule,
    QuicklinkModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    FullCalendarModule,
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    TypeaheadModule.forRoot()
  ],
  exports: [
    CommonModule,
    QuicklinkModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    FullCalendarModule,
    BsDatepickerModule,
    BsDropdownModule,
    TypeaheadModule,
    ...components,
    ...pipes
  ]
})
export class SharedModule { }

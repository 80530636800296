import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { tap, filter, map, catchError } from 'rxjs/operators';
import { User } from '@models/user.model';
import { Router } from '@angular/router';
import { ApiService } from './api.service';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private subject$ = new BehaviorSubject<User>(null);
  public user$ = this.subject$.asObservable().pipe(filter((x) => x !== null));

  constructor(
    private api: ApiService,
    private token: TokenService,
    private router: Router
  ) {
    this.initAuth().subscribe();
    // this.user$.subscribe(() => this.api.init(this.token.getToken()));
  }

  get user(): User {
    return this.subject$.value;
  }

  public initAuth(): Observable<boolean> {
    const token = this.token.getToken();
    if (token) {
      return this.api.get('/auth/token').pipe(
        map((res) => this.setAuth(res.data, token)),
        catchError(() => of(this.signOut()))
      );
    }
    return of(this.purgeAuth());
  }

  private setAuth(user: User, token: string) {
    this.token.saveToken(token);
    this.subject$.next(user);
    return true;
  }

  private purgeAuth() {
    this.token.destroyToken();
    this.subject$.next({} as User);
    return false;
  }

  signIn(credentials) {
    return this.api
      .post('/auth/login', credentials)
      .pipe(tap((res) => this.setAuth(res.data.user, res.data.token)));
  }

  signOut() {
    this.purgeAuth();
    this.router.navigate(['/']);
    return false;
  }
}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  getToken(): string {
    return window.localStorage.accessToken;
  }

  saveToken(token: string) {
    window.localStorage.accessToken = token;
  }

  destroyToken() {
    window.localStorage.removeItem('accessToken');
  }
}

import { Component, OnInit } from '@angular/core';
import { SlideInOutAnimation } from '@utils/animations';
import { AuthService } from '@services/auth.service';
import { faLandmark } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [SlideInOutAnimation]
})
export class HeaderComponent implements OnInit {
  collapsed = true;
  logo = faLandmark;

  constructor(public auth: AuthService) {}

  ngOnInit() {}
}

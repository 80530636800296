import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import * as io from 'socket.io-client';
import Echo from 'laravel-echo';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  public echo: Echo;

  constructor(private http: HttpClient) {}

  init(token: string) {
    (window as any).io = io;
    this.echo = new Echo({
      broadcaster: 'socket.io',
      host: `${environment.apiUrl + environment.socketPath}`,
      auth: { headers: { Authorization: `Bearer ${token}` } },
    });
  }

  get(path: string, params = {}): Observable<any> {
    return this.http.get(
      `${environment.apiUrl + environment.restPath + path}`,
      params
    );
  }

  put(path: string, body: object = {}): Observable<any> {
    return this.http.put(
      `${environment.apiUrl + environment.restPath + path}`,
      JSON.stringify(body)
    );
  }

  post(path: string, body: object = {}): Observable<any> {
    return this.http.post(
      `${environment.apiUrl + environment.restPath + path}`,
      JSON.stringify(body)
    );
  }

  delete(path: string): Observable<any> {
    return this.http.delete(
      `${environment.apiUrl + environment.restPath + path}`
    );
  }
}
